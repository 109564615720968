import React from "react"
import TitlePixelPointInlineSvg from "../images/title-pixel-point.inline.svg"

const Footer = () => (
  <footer className="footer page__container">
    Made by <a href="https://pixelpoint.io" target="_blank"><TitlePixelPointInlineSvg/></a> – Web, Mobile, AI,
    Blockchain development company. © 2019 Kube Forwarder. All right reserved
  </footer>
)

export default Footer
