import React from "react"
import LogoInlineSvg from "../images/logo.inline.svg"
import GitHubButton from "react-github-btn"

export default class Header extends React.Component {
  render() {
    const { className } = this.props

    return (
      <div className={"header page__container " + className}>
        <div className="header__site">
          <LogoInlineSvg className="header__logo"/>
          <div className="header__site-name">Kube Forwarder</div>
        </div>
        <div className="space"/>
        <ul className="navigation">
          <li><a className="navigation__item" onClick={this.scrollTo.bind(this, "features")}>Features</a></li>
          <li>
            <a className="navigation__item" onClick={this.scrollTo.bind(this, "simple-secure")}>Advantages</a>
          </li>
          <li><a className="navigation__item" onClick={this.scrollTo.bind(this, "works-with")}>Works with</a></li>
        </ul>
        <GitHubButton className="header__github-star" href="https://github.com/pixel-point/kube-forwarder"
                      data-size="large"
                      aria-label="Star pixel-point/kube-forwarder on GitHub">Star</GitHubButton>
      </div>
    )
  }

  scrollTo(id) {
    const element = document.getElementById(id)
    if (element) element.scrollIntoView({ behavior: 'smooth' })
  }
}
