import React from "react"

import { reachGoal } from "../../lib/metrika"
import AppleLogoInlineSvg from "../../images/logo-apple.inline.svg"
import WindowsLogoInlineSvg from "../../images/logo-windows.inline.svg"
import LinuxLogoInlineSvg from "../../images/logo-linux.inline.svg"

class ButtonDownload extends React.Component {
  constructor() {
    super()
    this.state = { platform: 'none' }
  }

  render() {
    const { platform } = this.state
    const isMac = platform.startsWith('Mac');
    const isWin = platform === 'Win32';
    const isLinux = platform.startsWith('Linux');

    let goalName = 'download'
    let href = 'https://github.com/pixel-point/kube-forwarder/releases/latest'
    if (isMac) {
      href += '/download/kube-forwarder.dmg'
      goalName = 'download:mac'
    }
    if (isWin) {
      href += '/download/kube-forwarder.exe'
      goalName = 'download:windows'
    }
    if (isLinux) {
      href += '/download/kube-forwarder.AppImage'
      goalName = 'download:linux'
    }

    function onClick() {
      reachGoal(goalName)
    }

    return (
      <a className="button-download button button_theme_primary" href={href} onClick={onClick}>
        { isMac && <span className="button__icon button__icon_apple"><AppleLogoInlineSvg/></span> }
        { isWin && <span className="button__icon button__icon_windows"><WindowsLogoInlineSvg/></span> }
        { isLinux && <span className="button__icon button__icon_linux"><LinuxLogoInlineSvg/></span> }
        Download
      </a>
    )
  }

  componentDidMount() {
    let platform = 'none';
    if (typeof window !== `undefined`) {
      platform = window.navigator ? window.navigator.platform : 'none'
    }

    // it's important to change state in componentDidMount to override html generated by SSR
    this.setState({ platform })
  }
}

export default ButtonDownload
