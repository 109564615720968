import React from "react"
import PropTypes from "prop-types"

import Header from "../components/header"
import Footer from "./footer"

const Layout = ({ children, className, parallax }) => {
  const content = <div className={className}>
    <Header/>
    {children}
    <Footer/>
  </div>

  if (parallax) {
    return <div className="page__parallax-wrapper">{ content }</div>
  }

  return content
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  parallax: PropTypes.bool,
}

export default Layout
